<template>
    <Dialog
        class="dialog-style"
        :showHeader="false"
        :visible.sync="show"
        :contentStyle="{overflowY: 'auto !important'}"
        @keydown.esc="show = !closable"
        modal="modal"
    >
        <div class="dialog-content">
            <h2 class="ta-center text-primary mt-0">Selecione o estabelecimento</h2>

            <div class="p-fluid">
                <div class="p-col-12" style="padding-bottom: 0;">
                    <span class="input-icon-right">
                        <i class="pi pi-spin pi-spinner" v-if="isTyping"></i>
                        <i class="pi pi-search" v-else></i>
                        <input
                            class="p-inputtext p-component"
                            ref="filtroNomeRef"
                            v-model="searchEstablishmentText"
                            @input="isTyping = true"
                            @keyup.enter="selectEstablishment()"
                            @keydown.up.prevent="onKeyUp()"
                            @keydown.down.prevent="onKeyDown()"
                            placeholder="Buscar"
                            type="text"
                        />
                    </span>
                </div>
                <div class="p-field p-col-12" style="padding-top: 0;">
                    <div
                        class="datatable p-datatable p-component p-datatable-hoverable-rows"
                        v-if="visibleEstablishments.length"
                        @keydown="onRowKeyDown($event)"
                    >
                        <div class="p-datatable-wrapper">
                            <table role="grid">
                                <tbody class="p-datatable-tbody">
                                <tr
                                    class="p-datatable-row"
                                    v-for="estabelecimento in visibleEstablishments"
                                    :key="estabelecimento.id"
                                    :ref="refEstablishmentRow(estabelecimento.id)"
                                    :class="{'p-highlight': estabelecimento.id === selectedEstablishment.id}"
                                    @click="selectedEstablishment = estabelecimento"
                                    tabindex="-1"
                                >
                                    <td>{{ estabelecimento.nm_fantasia }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="empty-result ta-center" v-else>
                        <span>Nenhum item encontrado</span>
                    </div>
                </div>
            </div>
            <div class="ta-right px-2">
                <Button
                    v-if="closable"
                    class="p-button-secondary mr-2"
                    label="Fechar"
                    icon="pi pi-times"
                    @click="show = false"
                />
                <Button
                    class="p-button-info"
                    label="Selecionar"
                    icon="pi pi-check"
                    @click="selectEstablishment()"
                    :disabled="!this.selectedEstablishment"
                />
            </div>
        </div>
    </Dialog>
</template>

<style lang="scss">
.dialog-style {
    width: 600px;
    max-width: 96%;
    max-height: 90%;
    @media (min-height: 500px) {
        position: absolute;
        top: 8%;
    }
    @media (min-height: 700px) {
        position: absolute;
        top: 12%;
    }
    .dialog-content {
        .datatable {
            //padding-top: 0;
            max-height: 400px;
            overflow: auto;
            .p-datatable-thead {
                visibility: collapse;
            }
        }
        .datatable::-webkit-scrollbar {
            width: 6px;
        }
        .datatable::-webkit-scrollbar-track {
            background-color: #e4e4e4;
            border-radius: 100px;
        }
        .datatable::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 100px;
        }
        .input-icon-right {
            position: relative;
            //padding-bottom: 0;
        }
        .input-icon-right > i:last-of-type {
            color: #6c757d;
            position: absolute;
            right: 0.75rem;
            top: 0.05rem;
        }
        .empty-result {
            border: 1px solid #c8c8c8;
        }
        fieldset {
            margin: 10px;
            border: 1px solid #c8c8c8;
        }
        .text-title {
            color: #333;
            font-size: 14px;
            font-weight: 700;
        }
    }
}
</style>

<script>
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';

    const _ = require('lodash');

    export default {
        components: {Button, Dialog},
        props: ['visible', 'closable'],
        watch: {
            searchEstablishmentText: _.debounce(function() { this.isTyping = false; }, 300),
            isTyping: function(val) {
                if (!val) this.visibleEstablishments = this.filterByName(this.searchEstablishmentText);
            },
            visibleEstablishments() {
                if (this.visibleEstablishments.length) {
                    this.selectedEstablishment = this.visibleEstablishments[0];
                } else {
                    this.selectedEstablishment = null;
                }
            }
        },
        computed: {
            show: {
                get() { return this.visible; },
                set(val) { if (!val) this.$emit('close'); }
            }
        },
        mounted() {
            let userData = JSON.parse(localStorage.getItem('usuario'))
            this.establishments = userData.estabelecimentos.map((establishment, idx) => {
                return {
                    idx: idx,
                    id: establishment.id,
                    nm_fantasia: establishment.nm_fantasia,
                    cd_cidade: establishment.cd_cidade
                }
            })
            this.visibleEstablishments = [...this.establishments]
            this.focusOnSearchInput();
        },
        data() {
            return {
                searchEstablishmentText: '',
                isTyping: false,
                establishments: [],
                selectedEstablishment: null,
                visibleEstablishments: [],
                loadingOptions: false,
            }
        },
        methods: {
            onRowKeyDown(event) {
                switch (event.which) {
                    // enter
                    case 13:
                        this.selectEstablishment();
                        event.preventDefault();
                        break;

                    // up arrow
                    case 38:
                        this.onKeyUp();
                        event.preventDefault();
                        break;

                    // down arrow
                    case 40:
                        this.onKeyDown();
                        event.preventDefault();
                        break;

                    default:
                        this.focusOnSearchInput();
                        break;
                }
            },
			onKeyDown() {
				if (!this.selectedEstablishment) return;

				const lastIdx = this.visibleEstablishments.length - 1;
				const idx = this.getIndexEstablishment(this.selectedEstablishment.id);

				if (idx >= 0 && idx < lastIdx) {
					this.selectedEstablishment = this.visibleEstablishments[idx + 1];
					this.focusOnRow(this.selectedEstablishment.id);
				}
			},
			onKeyUp() {
				if (!this.selectedEstablishment) return;

				const idx = this.getIndexEstablishment(this.selectedEstablishment.id);

				if (idx > 0) {
					this.selectedEstablishment = this.visibleEstablishments[idx - 1];
					this.focusOnRow(this.selectedEstablishment.id);
				}
			},
			focusOnRow(establishmentId) {
                const row = this.$refs[this.refEstablishmentRow(establishmentId)]
                if (Array.isArray(row) && row.length) row[0].focus()
            },
			focusOnSearchInput() {
				this.$refs.filtroNomeRef?.focus();
			},
			formatToCompareStr(str) {
				return (str || "").normalize('NFD').replace(/\p{Diacritic}/gu, "").toUpperCase();
			},
			filterByName(name, establishments) {
				establishments = establishments || this.establishments;
				if (name && name.length) {
					name = this.formatToCompareStr(name);
					return establishments?.filter((e) => this.formatToCompareStr(e.nm_fantasia).includes(name)) || [];
				}
				return establishments;
			},
			getIndexEstablishment(establishmentId) {
                return this.visibleEstablishments.findIndex((e) => e.id === establishmentId);
            },
			refEstablishmentRow(establishmentId) {
				return `refEstablishmentRow-${establishmentId}`;
			},
			selectEstablishment() {
				if (!this.selectedEstablishment) return;
				let userData = JSON.parse(localStorage.getItem('usuario'));
				if (userData.selecionarEstabelecimento) {
					userData.selecionarEstabelecimento = false;
					localStorage.setItem('usuario', JSON.stringify(userData));
				}
				this.$emit('selected', this.selectedEstablishment.idx);
				this.show = false;
			}
		}
    }
</script>
