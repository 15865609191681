<template>
    <Dialog
        class="dialog-city-style"
        :showHeader="false"
        :visible.sync="show"
        :contentStyle="{overflowY: 'auto !important'}"
        @keydown.esc="show = false"
        modal="modal"
    >
        <div class="dialog-content">
            <h2 class="ta-center text-primary mt-0">Selecione a cidade</h2>
            <div class="p-grid p-fluid" style="padding-inline: 10px; margin-bottom: 30px;">
                <div class="p-col-12">
                    <label class="form-label">Estado:</label>
                    <Dropdown
                        v-model="idSelectedState"
                        :options="stateOptions"
                        @change="filter()"
                        dataKey="id"
						optionValue="id"
                        optionLabel="nm_estado"
                        placeholder="Selecione"
                    />
                </div>
            </div>
            <div class="p-fluid">
                <div class="p-col-12" style="padding-bottom: 0;">
                    <span class="input-icon-right">
                        <i class="pi pi-spin pi-spinner" v-if="isTyping"></i>
                        <i class="pi pi-search" v-else></i>
                        <input
                            class="p-inputtext p-component"
                            ref="filtroNomeRef"
                            v-model="searchCityText"
                            @input="isTyping = true"
                            @keyup.enter="selectCity()"
                            @keydown.up.prevent="onKeyUp()"
                            @keydown.down.prevent="onKeyDown()"
                            placeholder="Buscar"
                            type="text"
                        />
                    </span>
                </div>
                <div class="p-field p-col-12" style="padding-top: 0;">
                    <div
                        class="datatable p-datatable p-component p-datatable-hoverable-rows"
                        v-if="visibleCities.length"
                        @keydown="onRowKeyDown($event)"
                    >
                        <div class="p-datatable-wrapper">
                            <table role="grid">
                                <tbody class="p-datatable-tbody">
                                <tr
                                    class="p-datatable-row"
                                    v-for="city in visibleCities"
                                    :key="city.id"
                                    :ref="refCityRow(city.id)"
                                    :class="{'p-highlight': city.id === idSelectedCity}"
                                    @click="idSelectedCity = city.id"
                                    tabindex="-1"
                                >
                                    <td>{{ city.text }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="empty-result ta-center" v-else>
                        <span>Nenhum item encontrado</span>
                    </div>
                </div>
            </div>
            <div class="ta-right px-2">
                <Button
                    class="p-button-secondary mr-2"
                    label="Fechar"
                    icon="pi pi-times"
                    @click="show = false"
                />
                <Button
                    class="p-button-info"
                    label="Selecionar"
                    icon="pi pi-check"
                    @click="selectCity()"
                    :disabled="!this.idSelectedCity"
                />
            </div>
        </div>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

const _ = require('lodash');

export default {
    components: {Button, Dialog, Dropdown},
	props: ['visible'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    watch: {
        searchCityText: _.debounce(function() { this.isTyping = false; }, 300),
        isTyping: function(val) {
            if (!val) this.filter();
        },
        visibleCities() {
            if (this.visibleCities.length) this.idSelectedCity = this.visibleCities[0]?.id;
            else this.idSelectedCity = null;
        }
    },
    mounted() {
        let userData = JSON.parse(localStorage.getItem('usuario'));
        this.stateOptions = userData?.opcoes_estado || [];
        this.stateOptions.unshift({ id: null, nm_estado: "- Selecione -"});
        this.cityOptions = userData?.opcoes_cidade?.map((c) => {
            return { text: `${c.nm_cidade} - ${c.ds_uf}`, ...c };
        }) || [];
		this.visibleCities = [...this.cityOptions];
		if (userData?.idxCidadeSelecionada) {
			this.$nextTick(() => this.idSelectedCity = this.cityOptions[userData.idxCidadeSelecionada]?.id)
		}
		this.focusOnSearchInput();
    },
	data() {
		return {
            isTyping: false,
            searchCityText: "",
			idSelectedState: null,
			idSelectedCity: null,
            stateOptions: [],
            cityOptions: [],
            visibleCities: []
		}
	},
	methods: {
        onRowKeyDown(event) {
            switch (event.which) {
                // enter
                case 13:
                    this.selectCity();
                    event.preventDefault();
                    break;

                // up arrow
                case 38:
                    this.onKeyUp();
                    event.preventDefault();
                    break;

                // down arrow
                case 40:
                    this.onKeyDown();
                    event.preventDefault();
                    break;

                default:
                    this.focusOnSearchInput();
                    break;
            }
        },
        onKeyDown() {
            if (!this.idSelectedCity) return;

            const lastIdx = this.visibleCities.length - 1;
            const idx = this.getCityIndex(this.idSelectedCity);

            if (idx >= 0 && idx < lastIdx) {
                this.idSelectedCity = this.visibleCities[idx + 1]?.id;
                this.focusOnRow(this.idSelectedCity);
            }
        },
        onKeyUp() {
            if (!this.idSelectedCity) return;

            const idx = this.getCityIndex(this.idSelectedCity);

            if (idx > 0) {
                this.idSelectedCity = this.visibleCities[idx - 1]?.id;
                this.focusOnRow(this.idSelectedCity);
            }
        },
        focusOnRow(cityId) {
            const row = this.$refs[this.refCityRow(cityId)]
            if (Array.isArray(row) && row.length) row[0].focus()
        },
        focusOnSearchInput() {
            this.$refs.filtroNomeRef?.focus();
        },
        refCityRow(cityId) {
            return `refCityRow-${cityId}`;
        },
        getCityIndex(cityId) {
            return this.visibleCities.findIndex((e) => e.id === cityId);
        },
        formatToCompareStr(str) {
            return (str || "").normalize('NFD').replace(/\p{Diacritic}/gu, "").toUpperCase();
        },
        filter() {
            let cities = [...this.cityOptions];
            cities = this.filterByEstate(this.idSelectedState, cities);
            cities = this.filterByName(this.searchCityText, cities);
            this.visibleCities = cities;
        },
        filterByName(name, cities) {
            cities = cities || [...this.cityOptions];
            name = name || this.searchCityText;
            if (name && name.length) {
                name = this.formatToCompareStr(name);
                return cities?.filter((c) => this.formatToCompareStr(c.text).includes(name)) || [];
            }
            return cities;
        },
        filterByEstate(estateId, cities) {
            cities = cities || [...this.cityOptions];
            estateId = estateId || this.idSelectedState;
            if (estateId) return cities?.filter((c) => c.cd_estado === estateId) || [];
            return cities;
        },
		selectCity() {
			if (!this.idSelectedCity) return;
            this.$emit('selected', this.idSelectedCity);
            this.show = false;
		}
	}
}
</script>

<style lang="scss">
.dialog-city-style {
    width: 600px;
    max-width: 96%;
    max-height: 90%;
    @media (min-height: 500px) {
        position: absolute;
        top: 8%;
    }
    @media (min-height: 700px) {
        position: absolute;
        top: 12%;
    }
    .dialog-content {
        .datatable {
            //padding-top: 0;
            max-height: 400px;
            overflow: auto;
            .p-datatable-thead {
                visibility: collapse;
            }
        }
        .datatable::-webkit-scrollbar {
            width: 6px;
        }
        .datatable::-webkit-scrollbar-track {
            background-color: #e4e4e4;
            border-radius: 100px;
        }
        .datatable::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 100px;
        }
        .input-icon-right {
            position: relative;
            //padding-bottom: 0;
        }
        .input-icon-right > i:last-of-type {
            color: #6c757d;
            position: absolute;
            right: 0.75rem;
            top: 0.05rem;
        }
        .empty-result {
            border: 1px solid #c8c8c8;
        }
    }
}
</style>
