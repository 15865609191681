<template lang="pug">
    Dialog(:showHeader="false" :visible.sync="show" @keydown.esc="$emit('close')" modal :closable="false"
        :style="{minWidth: '40vw', maxWidth: '90%'}" :contentStyle="{overflowY: 'auto !important'}")
        form(@submit.prevent="handleSubmit()")
            .p-fluid
                h3.text-title.ta-center.text-primary.mt-0 Trocar senha
                .p-field.p-col-12(:class="{ 'form-group--error': submitted && $v.form.password.$error }")
                    label(for='password') <b>Senha atual:</b>
                    Password#password(v-model="$v.form.password.$model" :feedback="false")
                    .feedback--errors(v-if='submitted && $v.form.password.$error')
                        .form-message--error(v-if="!$v.form.password.required") Senha é obrigatória.
                        .form-message--error(v-if="!$v.form.password.minLength") Senha deve conter pelo menos 6 caracteres.
                .p-field.p-col-12(:class="{ 'form-group--error': submitted && $v.form.newPassword.$error }")
                    label(for='new_password') <b>Nova senha:</b>
                    Password#new_password(v-model="$v.form.newPassword.$model" :feedback="false")
                    .feedback--errors(v-if='submitted && $v.form.newPassword.$error')
                        .form-message--error(v-if="!$v.form.newPassword.required") Senha é obrigatória.
                        .form-message--error(v-if="!$v.form.newPassword.minLength") Senha deve conter pelo menos 6 caracteres.
                .p-field.p-col-12(:class="{ 'form-group--error': submitted && $v.form.newPasswordConfirm.$error }")
                    label(for='new_password_confirm') <b>Cofirmar nova senha:</b>
                    Password#new_password_confirm(v-model="$v.form.newPasswordConfirm.$model" :feedback="false")
                    .feedback--errors(v-if='submitted && $v.form.newPasswordConfirm.$error')
                        .form-message--error(v-if="!$v.form.newPasswordConfirm.required") Senha é obrigatória.
                        .form-message--error(v-if="!$v.form.newPasswordConfirm.confirmPassword") Senhas não coincidem.

            .ta-right
                .px-4(v-if='waiting')
                    ProgressSpinner.waitingNomeUsuario(strokeWidth='4' style="width:30px;height:30px")
                .px-2(v-else)
                    Button.p-button-secondary.mr-2(label="Fechar" icon="pi pi-times" @click="show=false")
                    Button.p-button-info(label="Enviar" icon="pi pi-check" type="submit")
</template>

<style scoped>

</style>

<script>
    import Button from 'primevue/button'
    import Dialog from 'primevue/dialog'
    import Password from 'primevue/password'
    import ProgressSpinner from 'primevue/progressspinner'
    import Usuario from "@/middleware/controllers/Usuario"
    import { required, minLength, sameAs } from 'vuelidate/lib/validators'

    export default {
        components: { Button, Dialog, Password, ProgressSpinner },
        props: ['display'],
        computed: {
            show: {
                get() {
                    return this.display
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        data() {
            return {
                waiting: false,
                submitted: false,
                form: {
                    password: '',
                    newPassword: '',
                    newPasswordConfirm: ''
                }
            }
        },
        validations: {
            form: {
                password: { required, minLength: minLength(6) },
                newPassword: { required, minLength: minLength(6) },
                newPasswordConfirm: { required, confirmPassword: sameAs('newPassword') }
            }
        },
        methods: {
            handleSubmit() {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.waiting = true
                Usuario.changePassword(this.form.password, this.form.newPassword, this.form.newPasswordConfirm)
                    .then(response => {
                        this.waiting = false
                        if ([200, 201, 204].includes(response.status)) {
                            this.$toast.info('Senha alterada com sucesso', { duration: 3000 })
                            this.show = false
                        } else if ([400, 401, 403].includes(response.status)) {
                            if (response.data.detail) {
                                if (typeof response.data.detail == 'string')
                                    this.$toast.error(response.data.detail, { duration: 3000 })
                                else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            }
                        }
                    })
            }
        }
    }
</script>
