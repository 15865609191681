<template>
    <Dialog
        class="dialog-grupos-style"
        @keydown.esc="show = false"
        :visible.sync="show"
        :showHeader="false"
        :contentStyle="{overflowY: 'auto !important'}"
        modal="modal"
    >
        <div class="dialog-content">
            <div class="p-grid">
                <div class="p-col-9">
                    <div class="mr-1"><b style="font-size: 16px">Meus grupos:</b></div>
                </div>
                <div class="p-col-3">
                    <div class="ta-right px-1">
                        <button class="close-button" @click="show = false">
                            <span class="pi pi-times close-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
            <template v-for="group in list">
                <div class="p-chip p-component" :key="group.id" style="margin: 0 2px 2px 0;">
                    <div class="p-chip-text">{{ group.nm_grupo }}</div>
                </div>
            </template>
        </div>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    components: {Dialog},
    props: ['visible', 'list'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.dialog-grupos-style {
    width: 380px;
    max-width: 96%;
    max-height: 90%;

    .close-button {
        background-color: #fff;
        border: none;
        &:focus {
            border: none;
        }
        .close-icon {
            color: #999;
            cursor: pointer;

            &:hover {
                color: #000;
            }
        }
    }

    .p-chip {
        display: inline-block;
        background-color: #dee2e6;
        color: #495057;
        border-radius: 16px;
        padding: 0 0.75rem;

        .p-chip-text {
            line-height: 1.5;
            margin-top: 0.375rem;
            margin-bottom: 0.375rem;
        }
    }
}
</style>
