function ordenarItens(a, b) {
    if (a.label === "Comunicação Interna") {
        return -1; // 'Comunicação Interna' vem primeiro
    } else if (b.label === "Comunicação Interna") {
        return 1; // 'Comunicação Interna' vem depois de todos os outros
    } else {
        return a.label.localeCompare(b.label); // Ordenação alfabética para os demais itens
    }
}

const menuItems = (vm) => {
    
    let usuario = JSON.parse(localStorage.getItem('usuario'))
    let menusPerfilMedico = [ 'atendimento-medico', 'relatorio-medico' ]

    let items = []
    if (usuario) {
        let mem = {}
        Object.keys(usuario.menu).forEach(key => {

            let _itemsPush = (_items, _key) => {
                _items.push({
                    label: usuario.menu[_key].title,
                    icon: usuario.menu[_key].icon,
                    class: {
                        selected: vm.$route.path.split('/')[1] == _key,
                        perfilMedico: menusPerfilMedico.includes(_key),
                        perfilAdministrativo: ! menusPerfilMedico.includes(_key)
                    },
                    command: () => {
                        vm.$router.push({ name: _key }).catch(() => {})
                        vm.sidebarTrigger = false
                    }
                })
            }
            
            if (usuario.menu[key].children) {

                let item = {
                    label: usuario.menu[key].title,
                    icon: usuario.menu[key].icon,
                    class: {
                        selected: vm.$route.path.split('/')[1] == key,
                        perfilMedico: menusPerfilMedico.includes(key),
                        perfilAdministrativo: ! menusPerfilMedico.includes(key)
                    },
                    items: []
                }

                usuario.menu[key].children.forEach(keyChild => {
                    if (! mem[keyChild]) {
                        mem[keyChild] = true
                        _itemsPush(item.items, keyChild)
                    }
                })

                items.push(item)
            }

            else if (! mem[key]) _itemsPush(items, key)

        })
    }

    items.sort(ordenarItens);

    return items
}

export default menuItems