<template lang="pug">
    div(:class='{ "em-atendimento": $route.name == "em-atendimento", wrapperPerfilAdministrativo: perfil == 1,  wrapperPerfilMedico: perfil == 2 }')
        .topbar(v-if='menuItems.length && $route.name != "em-atendimento"')
            .topbar-wrapper.p-grid.p-align-center

                .p-col-4
                    Button.sidebar-trigger.button-menu.p-button-secondary(icon="pi pi-bars" @click='sidebarTrigger = true')

                    .profile-wrapper(v-if='showPerfilToggle')
                        p Selecionar perfil:
                        SelectButton(v-model='perfil' :options='perfis' optionLabel='text' optionValue='value' @input='onPerfilChange')
                .p-col-4.ta-center
                    img.img-logo(src='./../assets/img/logo-medclub.png')
                .p-col-4.ta-right

                    ProgressSpinner(v-if='waitingLogout' strokeWidth='6')
                    .desktop-header(v-else-if='usuarioInfo')
                        .box-text-user
                            h1.text-name {{ usuarioInfo.firstName }} {{ usuarioInfo.lastName }}
                            h2.text-company
                                span.edit-hover(@click="selecionarEstabelecimento = true")
                                    i.jam.jam-write.mr-1
                                    | {{ usuarioInfo.estabelecimentoName }}
                            h2.text-location
                                span.edit-hover(@click="dialogSelecionarCidade = true")
                                    span.bold-hover
                                        i.jam.jam-map-marker.mr-1
                                        | {{ usuarioInfo.cidadeName }}
                            //h2.text-group {{ usuarioInfo.grupoTitle }}
                        .box-avatar(@click='topbarUserMenu')
                            div.aux1
                                .image-wrapper
                                    img.img(:src="usuarioInfo.imgSrc ? usuarioInfo.imgSrc : require('./../assets/img/profile-pic-1.jpg')")
                            div.aux2
                                i.jam.jam-chevron-down
                            TieredMenu(ref="topbarUserMenu" :model='topbarMenuItems' :popup="true")

                Sidebar(:visible.sync='sidebarTrigger')
                    .sidebar-user-info(v-if='usuarioInfo')
                        .p-grid.p-fluid.p-align-center
                            .p-col-3
                                .img-wrapper
                                    img.img(:src="usuarioInfo.imgSrc ? usuarioInfo.imgSrc : require('./../assets/img/profile-pic-1.jpg')")
                            .p-col-9
                                .box-text
                                    h1.text-name {{ usuarioInfo.firstName }} {{ usuarioInfo.lastName }}
                                    h2.text-company <b>{{ usuarioInfo.estabelecimentoName }}</b>
                                    h2.text-location <i class="jam jam-map-marker" /> Teresina Pi
                                    //h2.text-group {{ usuarioInfo.grupoTitle }}
                    .profile-wrapper-mobile(v-if='showPerfilToggle')
                        p Selecionar perfil:
                        SelectButton(v-model='perfil' :options='perfis' optionLabel='text' optionValue='value' @input='onPerfilChange')
                    Menu(:model='menuItems')

        TrocarSenha(v-if="displayTrocarSenha" :display="displayTrocarSenha" @close="displayTrocarSenha=false")

        ListaGrupos(:list="listaGrupos" :visible="dialogGrupos" @close="dialogGrupos = false")

        SelecionarCidade(
            v-if="dialogSelecionarCidade"
            :visible="dialogSelecionarCidade"
            @selected="selecionarCidade"
            @close="dialogSelecionarCidade = false")

        SelecinarEstabelecimento(
            v-if="selecionarEstabelecimento"
            :visible="selecionarEstabelecimento"
            :closable="!primeiraSelecao"
            @selected="selectEstabelecimento"
            @close="selecionarEstabelecimento = false")

        .menubar-wrapper
            Menubar.my-2(:model='menuItems')

        tela-bloqueio

        vue-page-transition(name='fade-in-right')
            router-view

        .main-footer(v-if='menuItems.length' :class="{ 'env-offset': showEnv }")
            p.text-footer.ta-center.text-secondary MedClub &reg; 2020<br/>A7 Participações

        .env(v-if='showEnv')
            p <b>Ambiente em uso: </b>{{ env }}
</template>

<style lang="scss">
.topbar {
    .p-tieredmenu {
        min-width: 256px;
        overflow-y: auto;
        max-height: 360px;
    }
    .topbar-menu-title {
        .p-menuitem-link {
            padding: 4px 8px !important;
            font-size: 12px;
            text-align: center;
            font-weight: 700 !important;
            background-color: #fff !important;
            .p-menuitem-text {
                color: #666 !important;
            }
        }
        border-bottom: 1px solid #c8c8c8;
    }
    .topbar-menu-item {
        .p-menuitem-text,
        .p-menuitem-icon {
            color: #657786 !important;
        }
    }
    .text-location {
        color: #888;
        font-weight: 400;
    }
    .sidebar-user-info {
        .text-location {
            font-size: 14px;
        }
    }
    .edit-hover {
        cursor: pointer;
        //.show-hover { visibility: hidden; }
        &:hover {
            //.show-hover { visibility: visible; }
            .bold-hover {
                font-weight: 550 !important;
            }
        }
    }
}
.env {
    background-color: #ff6a00;
    color: #fff;
    position: fixed;
    top: 0;
    left: 20%;
    width: 60%;
    border-radius: 0 0 100px 100px;
    text-align: center;
    padding: 5px;
    z-index: 10;
    -webkit-animation: blink-animation 1s linear infinite;
    p {
        margin: 0;
        text-transform: uppercase;
        font-size: 12px;
    }
    @media all and (max-width: 576px) {
        left: 0;
        width: 100%;
        border-radius: 0 0;
        padding: 4px;
        p {
            font-size: 11px;
        }
    }
}
@keyframes blink-animation {
    from {
        background-color: #ff6a00;
    }
    to {
        background-color: #ff2200;
    }
}

.main-footer.env-offset {
    padding-bottom: 16px;
}
.wrapperPerfilAdministrativo {
    .perfilMedico {
        display: none !important;
    }
}
.wrapperPerfilMedico {
    .perfilAdministrativo {
        display: none !important;
    }
}
.profile-wrapper {
    @media all and (max-width: 576px) {
        display: none;
    }
    p {
        margin: 0;
        margin-bottom: 2px;
        font-size: 12px;
        color: #666;
    }
    .p-button-text {
        font-size: 12px;
        padding: 2px 16px !important;
    }
}
.profile-wrapper-mobile {
    text-align: center;
    padding-bottom: 20px;
    background-color: #f4f4f4;
    p {
        margin: 0;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: 700;
        color: #666;
    }
    .p-button-text {
        font-size: 12px;
        padding: 2px 20px !important;
    }
}
</style>

<script>
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import ProgressSpinner from "primevue/progressspinner";
import TieredMenu from "primevue/tieredmenu";
import SelectButton from "primevue/selectbutton";

import configs from "./../middleware/configs";
import { Usuario } from "./../middleware";
import menuItems from "./menuItems";
import telaBloqueio from "./Usuario/TelaBloqueio";
import TrocarSenha from "@/components/Usuario/TrocarSenha";
import SelecinarEstabelecimento from "@/components/Usuario/SelecinarEstabelecimento";
import ListaGrupos from "@/components/Usuario/ListaGrupos";
import SelecionarCidade from "@/components/Usuario/SelecionarCidade";

export default {
    components: {
        SelecionarCidade,
        ListaGrupos,
        Button,
        Sidebar,
        Menu,
        ProgressSpinner,
        TieredMenu,
        Menubar,
        SelectButton,
        telaBloqueio,
        TrocarSenha,
        SelecinarEstabelecimento
    },
    beforeRouteEnter(from, to, next) {
        next(vm => vm.refreshUser());
    },
    watch: {
        $route: function() {
            this.menuItems = menuItems(this);
        },
        "$route.path": function(val) {
            if (
                ["atendimento-medico", "relatorio-medico"].includes(
                    val.split("/")[1]
                )
            )
                this.perfil = 2;
        }
    },
    mounted() {
        if (
            ["atendimento-medico", "relatorio-medico"].includes(
                this.$route.path.split("/")[1]
            )
        )
            this.perfil = 2;
        this.refreshUser();
    },
    data() {
        return {
            waitingLogout: false,
            sidebarTrigger: false,
            menuItems: [],
            topbarMenuItems: [],
            usuarioInfo: null,
            showPerfilToggle: false,
            perfil: 1,
            env: configs.env,
            showEnv: configs.showEnv,
            displayTrocarSenha: false,
            dialogSelecionarCidade: false,
            selecionarEstabelecimento: false,
            primeiraSelecao: false,
            dialogGrupos: false,
            listaGrupos: [],
            perfis: [
                { text: "Administrativo", value: 1 },
                { text: "Médico", value: 2 }
            ]
        };
    },
    methods: {
        refreshUser() {
            let usuario = JSON.parse(localStorage.getItem("usuario"));
            if (usuario) {
                this.primeiraSelecao = usuario.selecionarEstabelecimento;
                this.selecionarEstabelecimento = usuario.selecionarEstabelecimento;

                const cidadeSelecionada = usuario.opcoes_cidade[usuario.idxCidadeSelecionada];
                let usuarioInfo = {
                    firstName: usuario.firstName,
                    lastName: usuario.lastName,
                    estabelecimentoName:
                        usuario.estabelecimentos[
                            usuario.idxCurrentEstabelecimento
                        ].nm_fantasia,
                    cidadeName: `${cidadeSelecionada.nm_cidade} - ${cidadeSelecionada.ds_uf}`,
                    grupoTitle: ""
                };
                if (usuario.img) usuarioInfo.imgSrc = usuario.img;

                // usuario.grupos.forEach(grupo => {
                //     if (usuarioInfo.grupoTitle.length)
                //         usuarioInfo.grupoTitle += " / ";
                //     usuarioInfo.grupoTitle += `${grupo.nm_grupo}`;
                // });
                this.listaGrupos = usuario.grupos;

                this.refreshTopbarMenuItems();
                this.showPerfilToggle =
                    usuario.grupos.find(g => g.id == 3) &&
                    usuario.grupos.length > 1;

                this.usuarioInfo = usuarioInfo;
                this.menuItems = menuItems(this);
            } else {
                this.menuItems = [];
                this.usuarioInfo = null;
            }
        },
        topbarUserMenu(ev) {
            if (this.$refs.topbarUserMenu) this.$refs.topbarUserMenu.toggle(ev);
        },
        refreshTopbarMenuItems() {
            let usuario = JSON.parse(localStorage.getItem("usuario"));

            this.topbarMenuItems = [];

            if (usuario.estabelecimentos.length > 1) {
                this.topbarMenuItems.push({
                    label: "Selecionar estabelecimento",
                    icon: "jam jam-building",
                    command: () => (this.selecionarEstabelecimento = true),
                    class: "topbar-menu-item"
                });

                /*this.topbarMenuItems.push({
                    label: "Selecionar estabelecimento",
                    class: "topbar-menu-title"
                });
                usuario.estabelecimentos.forEach((estabelecimento, idx) => {
                    this.topbarMenuItems.push({
                        label: estabelecimento.nm_fantasia,
                        class: "topbar-menu-item",
                        command: () => this.selectEstabelecimento(idx),
                        icon:
                            usuario.idxCurrentEstabelecimento == idx
                                ? "jam jam-check"
                                : ""
                    });
                });
                this.topbarMenuItems.push({ separator: true });*/
            }

            this.topbarMenuItems.push({
                label: "Selecionar cidade",
                icon: "jam jam-map-marker",
                command: () => (this.dialogSelecionarCidade = true),
                class: "topbar-menu-item"
            });
            this.topbarMenuItems.push({
                label: "Meus grupos",
                icon: "jam jam-grid",
                command: () => (this.dialogGrupos = true),
                class: "topbar-menu-item"
            });
            this.topbarMenuItems.push({
                label: "Trocar senha",
                icon: "jam jam-key",
                command: () => (this.displayTrocarSenha = true),
                class: "topbar-menu-item"
            });
            this.topbarMenuItems.push({
                label: "Sair",
                icon: "jam jam-log-out",
                command: () => this.logout(),
                class: "topbar-menu-item"
            });
        },
        onPerfilChange(ev) {
            let usuario = JSON.parse(localStorage.getItem("usuario"));
            usuario.idxCurrentEstabelecimento = 0;
            if (ev == 2) {
                usuario.estabelecimentos = usuario.estabelecimentosGrupo.medico;
                localStorage.setItem("usuario", JSON.stringify(usuario));
                this.$router.push({ name: "atendimento-medico" });
            } else {
                usuario.estabelecimentos =
                    usuario.estabelecimentosGrupo.administrativo;
                localStorage.setItem("usuario", JSON.stringify(usuario));

                let starterRoute = "";

                if (Object.values(usuario.menu)[0].children)
                    starterRoute = Object.values(usuario.menu)[0].children[0];
                else starterRoute = Object.keys(usuario.menu)[0];

                this.$router.push({ name: starterRoute });
            }
            this.refreshTopbarMenuItems();
            this.usuarioInfo.estabelecimentoName =
                usuario.estabelecimentos[
                    usuario.idxCurrentEstabelecimento
                ].nm_fantasia;
        },
        logout() {
            this.waitingLogout = true;
            Usuario.logout().then(() => {
                this.refreshUser();
                this.perfil = 1;
                this.$router.push("/usuario/auth");
                this.waitingLogout = false;
                setTimeout(() => {
                    this.$toast.info("Sua sessão foi encerrada", {
                        duration: 3000,
                        position: "top"
                    });
                }, 1000);
            });
        },
        selecionarCidade(idCidade) {
            let usuario = JSON.parse(localStorage.getItem("usuario"));
            const idxCidade = usuario.opcoes_cidade?.findIndex(c => c.id === idCidade);
            if (idxCidade !== usuario.idxCidadeSelecionada) {
                usuario.idxCidadeSelecionada = idxCidade;
                localStorage.setItem("usuario", JSON.stringify(usuario));
                this.refreshUser();
                this.$router.go();
            }
        },
        selectEstabelecimento(idx) {
            let usuario = JSON.parse(localStorage.getItem("usuario"));

            let alterouEstebelecimento = idx !== usuario.idxCurrentEstabelecimento;
            if (this.primeiraSelecao || alterouEstebelecimento) {
                const idCidade = usuario.estabelecimentos[idx]?.cd_cidade;
                usuario.idxCidadeSelecionada = usuario.opcoes_cidade?.findIndex(c => c.id === idCidade);
                usuario.selecionarEstabelecimento = false;
                usuario.idxCurrentEstabelecimento = idx;
                localStorage.setItem("usuario", JSON.stringify(usuario));
                this.primeiraSelecao = false;
            }

            if (alterouEstebelecimento) {
                this.refreshUser();
                this.$router.go(0);
            }
        }
    }
};
</script>
