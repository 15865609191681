<template lang="pug">
    .lockscreen(v-if='usuario')
        .login-wrapper.p-grid.p-align-center(v-if='! usuario.sessionValid')
            .p-col.p-grid.p-fluid.p-justify-center
                .form-wrapper.p-col-12.p-sm-6.p-md-5.p-lg-4.p-xl-3
                    .ta-center
                        form(@submit.prevent="handleSubmit()")
                            h1.text-title.ta-center.text-primary Sua sessão expirou

                            p.text-help <b>@{{ usuario.username }}</b>, por favor, digite sua senha para continuar navegando.

                            .p-inputgroup.my-1(:class="{ 'form-group--error': form.submitted && $v.form.model.senha.$error }")
                                span.p-inputgroup-addon <i class="pi pi-lock"></i>
                                InputText(placeholder="Senha" type='password' v-model='$v.form.model.senha.$model')
                            .feedback--errors(v-if='form.submitted && $v.form.model.senha.$error')
                                .form-message--error(v-if="!$v.form.model.senha.required") <b>Senha</b> é obrigatório.
                            
                            .p-inputgroup.my-1
                                ProgressBar.progressbar--button(v-if='form.waiting' mode='indeterminate')
                                .w-100(v-else)
                                    Button.w-100(label="Continuar" type='submit')
                                    .ta-right.mt-1
                                        a.text-esqsuasenha.text-secondary(@click='backToLogin') Ou acesse com um usuário diferente

                    p.text-footer.ta-center.text-secondary MedClub &reg; 2020<br/>A7 Participações
</template>

<style lang="scss">
    .lockscreen {
        .login-wrapper {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(66, 133, 244, .8);
            z-index: 999;
            .text-help {
                font-size: 12px;
                margin-bottom: 20px;
            }
        }
    }
</style>

<script>
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import ProgressBar from 'primevue/progressbar'

    import { required } from 'vuelidate/lib/validators'
    import { Usuario } from '../../middleware'
    export default {
        components: { InputText, Button, ProgressBar },
        data () {
            return {
                form: {
                    model: {
                        senha: ''
                    },
                    submitted: false,
                    waiting: false
                },
                usuario: JSON.parse(localStorage.getItem('usuario'))
            }
        },
        validations: {
            form: {
                model: {
                    senha: { required }
                }
            }
        },
        methods: {
            handleSubmit () {
                this.form.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.form.waiting = true
                let idxCurrentEstabelecimento = this.usuario.idxCurrentEstabelecimento

                Usuario.login(this.usuario.username, this.form.model.senha).then(usuario => {
                    this.form.waiting = false
                    if (usuario) {
                        usuario.idxCurrentEstabelecimento = idxCurrentEstabelecimento
                        localStorage.setItem('usuario', JSON.stringify(usuario))
                        this.$router.go(0)
                    } else {
                        this.$toast.error('Credenciais inválidas, acesso negado', { duration: 3000 })
                    }
                })
            },
            backToLogin () {
                localStorage.clear()
                this.$router.go(0)
            }
        }
    }
</script>